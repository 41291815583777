import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo 
    title="Marie-Luce Beraudo, consultante Web spécialiste SEO" 
    description="SEO Alternative, micro-entreprise basée dans le pays de Gex près de Genève, vous propose des prestations en référencement naturel/SEO ainsi qu'en développement Web."
    />
    <div className="error-page-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="inner">
                        <h1 className="theme-color">404</h1>
                        <h2>Page non trouvée</h2>
                        <p>Nous sommes désolés. La page que vous recherchez a été déplacée ou n'a jamais existée</p>
                        <a className="rn-button" href="/">Retour à l'accueil</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)
export default NotFoundPage


